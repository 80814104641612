import React, { Component, Fragment } from 'react';
import { PageHeader, Button, List, Avatar, Card } from 'antd';
import { getToken } from './api-authorization/adalConfig';
import { Layout } from './Layout'

export class DatasetList extends Component {

    constructor(props) {
        super(props);
        this.state = { datasets: [], loading: true };

        this.getDatasets = this.getDatasets.bind(this);
    }

    componentDidMount() {
        this.getDatasets();
    }

    async getDatasets() {
        const token = getToken();
        const response = await fetch('datasets/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ datasets: data, loading: false });
    }

    /*async deleteTopic(topicId) {
        const token = await authService.getAccessToken();
        const response = await fetch('topics/delete?id=' + topicId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.text().then(this.getTopics());

        message.success('Topic was deleted.');
    }*/

    render() {
        return (
            <Fragment>
                <Layout
                    style={{
                        padding: '0 0',
                        marginTop: '12px',
                        marginBottom: '24px'
                    }}
                    title="Datasets"
                    extra={[
                        <Button key="1" type="primary" icon="plus" href="dataset_builder/create">
                            Create a new Dataset
                        </Button>
                    ]}
                >
                    <Card title={"G-View Datasets (" + this.state.datasets.length + ")"}>
               <List
                    loading={this.state.loading}
                    itemLayout="horizontal"
                    dataSource={this.state.datasets}
                    renderItem={item => (
                        <List.Item
                            actions={[<Button type="link" icon="edit" href={"dataset_builder/edit/" + item.dataSetId}>Edit</Button>]}
                                >
                                        <List.Item.Meta
                                        avatar={
                                            <Avatar size="large" icon="database" />
                                        }
                                        title={<span>{item.commonName} (Id = {item.dataSetId})</span>}
                                        description={item.description && item.description.replace(/<br\s*[\/]?>/gi, '\n')}
                                        />
                                </List.Item>
                            )}
                        />
                        </Card>
                </Layout>
            </Fragment>
        );
    }
}