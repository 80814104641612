import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';
import { getToken } from './api-authorization/adalConfig';

const { Option } = Select;

class IntersectLayerSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            availableLayers: []
        };

        this.getAvailableLayersByDatasetId = this.getAvailableLayersByDatasetId.bind(this);
    }

    componentDidMount() {
        if (this.props.datasetId) {
            this.getAvailableLayersByDatasetId(this.props.datasetId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.datasetId !== this.props.datasetId) {
            if (this.props.datasetId) {
                this.getAvailableLayersByDatasetId(this.props.datasetId);
            }
        }
    }

    async getAvailableLayersByDatasetId(datasetId) {
        const token = getToken();
        const response = await fetch('topics/dataset_layers?datasetId=' + datasetId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ availableLayers: data });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Fragment>

                {this.state.availableLayers &&
                    getFieldDecorator("intersectLayer", {
                        initialValue: this.props.intersectLayer ? this.props.intersectLayer : "Disabled",
                        rules: []
                    })(
                        <Select
                            placeholder="Select intersect layer (optional)"
                            style={{ width: '100%' }}
                            onBlur={this.props.onBlurHandler}
                        >
                            <Option key="Disabled" title="Disabled">Disabled (default)</Option>
                            {this.state.availableLayers.map((layer, i) => <Option key={layer.Key} title={layer.Value}>{layer.Value}</Option>)}
                        </Select>
                    )
                }
    
            </Fragment>

        );
    }
}

export default IntersectLayerSelector;