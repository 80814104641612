import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button, InputNumber, Tree, Card, Slider, Checkbox } from 'antd';
import DrillValueSelector from './DrillValueSelector';
import DateSelector from './DateSelector';
import OverrideLayerSelector from './OverrideLayerSelector';
import IntersectLayerSelector from './IntersectLayerSelector';

const { Option } = Select;
const { TreeNode } = Tree;

class TableCardEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.cardEditorState;
    }

    componentDidMount() {

    }

    handleFormChange = e => {
        this.props.form.validateFields((err, values) => {
            this.setState(values, () => {
                this.props.onValidateFormValues(this.state);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const widthSliderMarks = {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
            11: '11',
            12: '12'
        };

        const options = [{ value: "default", label: "Default" }];

        if (this.state.tableType === "TABLE_DATA") {
            options.push({ value: "multiValue", label: "Parse multiple value table data." });
        }
        else {
            options.push({ value: "top", label: "Show top results (specify number below)" })
            options.push({ value: "bottom", label: "Show bottom results (specify number below)" })
        }

        return (

            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left" onChange={this.handleFormChange}>
                <Card title="Presentation" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="Title">
                        {getFieldDecorator('title', {
                            initialValue: this.state.title,
                            rules: [{ required: true, message: 'Please enter' }]
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Show in a card">
                        {getFieldDecorator('isBoxed', {
                            initialValue: this.state.isBoxed,
                            rules: []
                        })(<Checkbox checked={this.state.isBoxed} />)}
                    </Form.Item>
                    <Form.Item label="Show card header">
                        {getFieldDecorator('showCardHeader', {
                            initialValue: this.state.showCardHeader,
                            rules: []
                        })(<Checkbox checked={this.state.showCardHeader} />)}
                    </Form.Item>
                    <Form.Item label="Show date in card title">
                        {getFieldDecorator('addDateToTitle', {
                            initialValue: this.state.addDateToTitle,
                            rules: []
                        })(<Checkbox checked={this.state.addDateToTitle} />)}
                    </Form.Item>
                    <Form.Item label="Width (1-12 columns)">
                        {getFieldDecorator('width', {
                            initialValue: this.state.width,
                            rules: []
                        })(<Slider min={1} max={12} marks={widthSliderMarks} onAfterChange={this.handleFormChange} />)}
                    </Form.Item>
                </Card>
                <Card title="Data" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="Dataset">
                        {getFieldDecorator('dataSetId', {
                            initialValue: this.state.dataSetId !== 0 ? this.state.dataSetId : null,
                            rules: [{ required: true, message: 'Please enter' }]
                        })(



                            <Select
                                showSearch
                                placeholder="Select a dataset"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={this.handleFormChange}
                            >

                                {this.props.dataSets.map(function (dataSet) {

                                    return (

                                        <Option value={dataSet.id}>{dataSet.name}</Option>

                                    )
                                })
                                }

                            </Select>
                        )

                        }
                    </Form.Item>
                    <DateSelector form={this.props.form} key={"DateSelector" + this.state.guid} date={[this.state.minDate, this.state.maxDate]} maxDateOffset={this.state.maxDateOffset} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} isRangeSelectionEnabled={true} />
                    <Form.Item label="Drill value(s)">
                        <DrillValueSelector form={this.props.form} key={"DrillValueSelector" + this.state.guid} drillValues={this.state.drillValues} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                    </Form.Item>
                </Card>
                <Card title="Table component behaviour" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="Table component">
                        {getFieldDecorator('tableType', {
                            initialValue: this.state.tableType,
                            rules: [{ required: true, message: 'Please enter' }]
                        })(
                            <Select
                                showSearch
                                placeholder="Select table type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={this.handleFormChange}
                            >
                                <Option value="CHART_DATA">Chart data</Option>
                                <Option value="MAP_DATA">Map data</Option>
                                <Option value="TABLE_DATA">Table data</Option>
                            </Select>
                        )
                        }
                    </Form.Item>
                    <Form.Item label="Table behaviour">
                        {getFieldDecorator('tableBehaviour', {
                            initialValue: this.state.tableBehaviour,
                            rules: [{ required: true, message: 'Please enter' }]
                        })
                            (<Select
                                showSearch
                                placeholder="Select table behaviour"
                                optionFilterProp="children"
                                filterOption={this.props.filterOption}
                                value={this.state.tableBehaviour}
                                onSelect={this.handleFormChange}>

                                {options.map(function (option) {
                                    return (<Option value={option.value}>{option.label}</Option>);
                                })}
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item label="Table behaviour setting">
                        {getFieldDecorator('tableBehaviourSetting', {
                            initialValue: this.state.tableBehaviourSetting,
                            rules: [{ required: true, message: 'Please enter' }]
                        })(<InputNumber />)}
                    </Form.Item>
                </Card>
                <Card title="Geographies" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="Use all geographies in selected layer">
                        {getFieldDecorator('replaceGeographiesWithFullExtentOfLayer', {
                            initialValue: this.state.replaceGeographiesWithFullExtentOfLayer,
                            rules: []
                        })(<Checkbox checked={this.state.replaceGeographiesWithFullExtentOfLayer}><span style={{ color: "#999999" }}>Check to use all geographies in user selected layer</span></Checkbox>)}
                    </Form.Item>
                    <Form.Item label="Auto-scale geographies">
                        {getFieldDecorator('autoScaleGeographies', {
                            initialValue: this.state.autoScaleGeographies,
                            rules: []
                        })(<Checkbox checked={this.state.autoScaleGeographies}><span style={{ color: "#999999" }}>Check to automatically adjust the layer and intersect selected geographies based on geography detail</span></Checkbox>)}
                    </Form.Item>
                    <Form.Item label="Intersect layer">
                        <IntersectLayerSelector form={this.props.form} key={"IntersectLayerSelector" + this.state.guid} intersectLayer={this.state.intersectLayer} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                    </Form.Item>
                    <Form.Item label="Override layer">
                        <OverrideLayerSelector form={this.props.form} key={"OverrideLayerSelector" + this.state.guid} overrideLayer={this.state.overrideLayer} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                    </Form.Item>
                </Card>
                <Card title="Extras" size="small">
                    <Form.Item label="Inner CSS">
                        {getFieldDecorator('innerCss', {
                            initialValue: this.state.innerCss,
                            rules: []
                        })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                    <Form.Item label="Outer CSS">
                        {getFieldDecorator('outerCss', {
                            initialValue: this.state.outerCss,
                            rules: []
                        })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                </Card>


            </Form>

        );
    }
}

const WrappedTableCardEditor = Form.create({ name: 'tableCardEditor' })(TableCardEditor);

export default WrappedTableCardEditor;