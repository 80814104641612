import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Tree, Card, Slider, Checkbox } from 'antd';
import DrillValueSelector from './DrillValueSelector';
import ChartPerspectiveSelector from './ChartPerspectiveSelector';
import DateSelector from './DateSelector';
import ComparisonLayerSelector from './ComparisonLayerSelector';

const { Option } = Select;
const { TreeNode } = Tree;

class ColumnChartCardEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.cardEditorState;
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            this.setState(values, () => {
                this.props.onValidateFormValues(this.state);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const widthSliderMarks = {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
            11: '11',
            12: '12'
        };

        return (

            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left" onChange={this.handleFormChange}>
                <Card title="Presentation" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Title">
                    {getFieldDecorator('title', {
                        initialValue: this.state.title,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                    <Form.Item label="Show in a card">
                        {getFieldDecorator('isBoxed', {
                            initialValue: this.state.isBoxed,
                            rules: []
                        })(<Checkbox checked={this.state.isBoxed} />)}
                    </Form.Item>
                    <Form.Item label="Show card header">
                        {getFieldDecorator('showCardHeader', {
                            initialValue: this.state.showCardHeader,
                            rules: []
                        })(<Checkbox checked={this.state.showCardHeader} />)}
                    </Form.Item>
                    <Form.Item label="Show date in card title">
                        {getFieldDecorator('addDateToTitle', {
                            initialValue: this.state.addDateToTitle,
                            rules: []
                        })(<Checkbox checked={this.state.addDateToTitle} />)}
                    </Form.Item>
                    <Form.Item label="Width (1-12 columns)">
                        {getFieldDecorator('width', {
                            initialValue: this.state.width,
                            rules: []
                        })(<Slider min={1} max={12} marks={widthSliderMarks} onAfterChange={this.handleFormChange} />)}
                    </Form.Item>
                </Card>
                <Card title="Data" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Dataset">
                    {getFieldDecorator('dataSetId', {
                        initialValue: this.state.dataSetId !== 0 ? this.state.dataSetId : null,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(



                                <Select
                                    showSearch
                                    placeholder="Select a dataset"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={this.handleFormChange}
                                >

                                    {this.props.dataSets.map(function(dataSet) {

                                        return (

                                            <Option value={dataSet.id}>{dataSet.name}</Option>

                                        )
                                    })
                                    }

                                </Select>
                    )

                }
                </Form.Item>
                <DateSelector form={this.props.form} key={"DateSelector" + this.state.guid} date={this.state.date} maxDateOffset={this.state.maxDateOffset} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} isRangeSelectionEnabled={false} />
                <Form.Item label="Drill value(s)">
                    <DrillValueSelector form={this.props.form} key={"DrillValueSelector" + this.state.guid} drillValues={this.state.drillValues} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                </Form.Item>
                <Form.Item label="Perspective">
                    <ChartPerspectiveSelector form={this.props.form} key={"ChartPerspectiveSelector" + this.state.guid} perspective={this.state.perspective} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                    </Form.Item>
                </Card>
                <Card title="Chart component behaviour" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Include quick filter">
                    {getFieldDecorator('isQuickFilterEnabled', {
                        initialValue: this.state.isQuickFilterEnabled,
                        rules: []
                    })(<Checkbox checked={this.state.isQuickFilterEnabled} />)}
                </Form.Item>
                <Form.Item label="Include legend">
                    {getFieldDecorator('isLegendEnabled', {
                        initialValue: this.state.isLegendEnabled,
                        rules: []
                    })(<Checkbox checked={this.state.isLegendEnabled} />)}
                    </Form.Item>
                </Card>
                <Card title="Chart component annotation (Vertical / X-axis)" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="X-axis annotation text">
                        {getFieldDecorator('xAxisAnnotationText', {
                            initialValue: this.state.xAxisAnnotationText,
                            rules: []
                        })(<Input placeholder="Leave blank if you do not wish to show an annonation" />)}
                    </Form.Item>
                    <Form.Item label="X-axis annotation value (category)">
                        {getFieldDecorator('xAxisAnnotationValue', {
                            initialValue: this.state.xAxisAnnotationValue,
                            rules: [{
                                required: this.state.xAxisAnnotationText,
                                message: 'Please enter a category or clear "X-axis annotation text" field'
                            }]
                        })(<Input placeholder="Set category on the axis where the annotation will be displayed" />)}
                    </Form.Item>
                </Card>
                <Card title="Chart component annotation (Horizontal / Y-axis)" size="small" style={{ marginBottom: '16px' }}>
                    <Form.Item label="Y-axis annotation text">
                        {getFieldDecorator('yAxisAnnotationText', {
                            initialValue: this.state.yAxisAnnotationText,
                            rules: []
                        })(<Input placeholder="Leave blank if you do not wish to show an annonation" />)}
                    </Form.Item>
                    <Form.Item label="Y-axis annotation value (number)">
                        {getFieldDecorator('yAxisAnnotationValue', {
                            initialValue: this.state.yAxisAnnotationValue,
                            rules: [{
                                required: this.state.yAxisAnnotationText,
                                message: 'Please enter a number or clear "Y-axis annotation text" field'
                            }]
                        })(<Input placeholder="Set value on the axis where the annotation will be displayed" />)}
                    </Form.Item>
                    <Form.Item label="Y-axis max value (number)">
                        {getFieldDecorator('yAxisMaxValue', {
                            initialValue: this.state.yAxisMaxValue,
                            rules: []
                        })(<Input placeholder="Set value to extend axis range to display annotation at all times (optional)" />)}
                    </Form.Item>
                </Card>
                <Card title="Geographies" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Aggregate geographies">
                    {getFieldDecorator('aggregateGeographies', {
                        initialValue: this.state.aggregateGeographies,
                        rules: []
                    })(<Checkbox checked={this.state.aggregateGeographies}><span style={{ color: "#999999" }}>Check to aggregate geographies in user selection into a single geography</span></Checkbox>)}
                </Form.Item>
                <Form.Item label="Auto-scale geographies">
                    {getFieldDecorator('autoScaleGeographies', {
                        initialValue: this.state.autoScaleGeographies,
                        rules: []
                    })(<Checkbox checked={this.state.autoScaleGeographies}><span style={{ color: "#999999" }}>Check to automatically adjust the layer and intersect selected geographies based on geography detail</span></Checkbox>)}
                </Form.Item>
                <Form.Item label="Comparison layer(s)">
                    <ComparisonLayerSelector form={this.props.form} key={"ComparisonLayerSelector" + this.state.guid} comparisonLayers={this.state.comparisonLayers} onBlurHandler={this.handleFormChange} datasetId={this.state.dataSetId} />
                    </Form.Item>
                </Card>
                <Card title="Extras" size="small">
                <Form.Item label="Inner CSS">
                    {getFieldDecorator('innerCss', {
                        initialValue: this.state.innerCss,
                        rules: []
                    })(<Input.TextArea rows={5} />)}
                </Form.Item>
                <Form.Item label="Outer CSS">
                    {getFieldDecorator('outerCss', {
                        initialValue: this.state.outerCss,
                        rules: []
                    })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                </Card>
            </Form>

        );
    }
}

const WrappedColumnChartCardEditor = Form.create({ name: 'columnChartCardEditor' })(ColumnChartCardEditor);

export default WrappedColumnChartCardEditor;