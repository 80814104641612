import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';
import { getToken } from './api-authorization/adalConfig';

const { Option } = Select;

class ChartPerspectiveSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartPerspectives: []
        };

        this.getChartPerspectivesByDatasetId = this.getChartPerspectivesByDatasetId.bind(this);
    }

    componentDidMount() {
        if (this.props.datasetId) {
            this.getChartPerspectivesByDatasetId(this.props.datasetId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.datasetId !== this.props.datasetId) {
            if (this.props.datasetId) {
                this.getChartPerspectivesByDatasetId(this.props.datasetId);
            }
        }
    }

    async getChartPerspectivesByDatasetId(datasetId) {
        const token = getToken();
        const response = await fetch('topics/chart_perspectives?datasetId=' + datasetId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ chartPerspectives: data });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Fragment>

                {this.state.chartPerspectives &&
                    getFieldDecorator('perspective', {
                        initialValue: this.props.perspective,
                        rules: [{ required: true, message: 'Please select' }]
                    })(
                        <Select
                            placeholder="Select chart perspective"
                            style={{ width: '100%' }}
                            onBlur={this.props.onBlurHandler}
                        >
                            {this.state.chartPerspectives.map((chartPerspectiveValue, i) => <Option key={chartPerspectiveValue.Key} title={chartPerspectiveValue.Value}>{chartPerspectiveValue.Value}</Option>)}
                        </Select>
                    )
                }
    
            </Fragment>

        );
    }
}

export default ChartPerspectiveSelector;