import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button, Checkbox } from 'antd';

const { Option } = Select;

class DrillColumnDefinitionEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.drillColumnDefinitionEditorState;
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            this.setState(values, () => {
                this.props.onValidateFormValues(this.state);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} labelAlign="left" onChange={this.handleFormChange}>
                <Form.Item label="Name">
                    {getFieldDecorator('name', {
                        initialValue: this.state.name,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Structure table">
                    {getFieldDecorator('structureTable', {
                        initialValue: this.state.structureTable,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Names table">
                    {getFieldDecorator('namesTable', {
                        initialValue: this.state.namesTable,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Data type hint">
                    {getFieldDecorator('dataTypeHint', {
                        initialValue: this.state.dataTypeHint,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Internally comparable">
                    {getFieldDecorator('internallyComparable', {
                        initialValue: this.state.internallyComparable,
                        rules: []
                    })(<Checkbox checked={this.state.internallyComparable} />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>

                </Form.Item>
            </Form>

        );
    }
}

const WrappedDrillColumnDefinitionEditor = Form.create({ name: 'drillColumnDefinitionEditor' })(DrillColumnDefinitionEditor);

export default WrappedDrillColumnDefinitionEditor;