import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Card } from 'antd';

const { Option } = Select;

class TopicSummaryEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received TopicSummaryEditor form values: ', values);

                this.props.onValidateFormValues(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left" onChange={this.handleFormChange}>
                <Card title="General properties" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Alias">
                    {getFieldDecorator('alias', {
                        initialValue: this.props.alias,
                        rules: [{ required: true, message: 'Please enter' }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Title">
                    {getFieldDecorator('title', {
                        initialValue: this.props.title,
                        rules: [{ required: true, message: 'Please enter' }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Description">
                    {getFieldDecorator('description', {
                        initialValue: this.props.description,
                        rules: [],
                    })(<Input.TextArea rows={5} />)}
                </Form.Item>
                <Form.Item label="Dashboard description">
                    {getFieldDecorator('dashboardDescription', {
                        initialValue: this.props.dashboardDescription,
                        rules: [],
                    })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                </Card>
                <Card title="Advanced properties" size="small">
                <Form.Item label="Tags">
                    {getFieldDecorator('tags', {
                        initialValue: this.props.tags,
                        rules: [],
                    })(
                        <Select mode="tags" style={{ width: '100%' }} placeholder="Enter tags" onBlur={this.handleFormChange}>
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Social Media Image URL">
                    {getFieldDecorator('socialMediaImageUrl', {
                        initialValue: this.props.socialMediaImageUrl,
                        rules: [],
                    })(<Input />)}
                    </Form.Item>
                </Card>
            </Form>

        );
    }
}

const WrappedTopicSummaryEditor = Form.create({ name: 'topicSummaryEditor' })(TopicSummaryEditor);

export default WrappedTopicSummaryEditor;